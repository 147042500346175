import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import ReactGA from 'react-ga';

import { gsap } from 'gsap';
import isEqual from 'react-fast-compare';

import { withTranslation } from 'react-i18next';
import { AiOutlineMore } from "react-icons/ai";

import { AmplitudeService } from '../../../services';

// import tabulaColapse from '../../../../images/Game/tabula_colapse.svg';
// import tabulaColapsed from '../../../../images/Game/tabula_colapsed.svg';

import Notification from './Notification';
import ContactSupport from '../ContactSupport/ContactSupport';

import Chat from './Chat/Chat';
import SendGift from './Modals/SendGift/SendGift';
import SendGiftMobile from './Modals/SendGift/SendGiftMobile';
import Players from './StudentZole/StudentZolePlayers';

import ScoreTable from '../../../../containers/Game2.0/ScoreTable';

import GameStats from './Components/GameStats';
import CardsOnTable from './StudentZole/CardsOnTableStudentZole';
import PreviousRound from './Modals/PreviousRound';
import EndResultModal from './Modals/EndResultModal/EndResultModal';

import Clock from '../Components/Components/Clock';

import ChooseGameType from './Components/ChooseGameType';
import ChooseGameTypeMobile from './Components/ChooseGameTypeMobile';
import DealingNewCards from './Components/DealingNewCards';

import AutomatedOverlay from './Components/AutomatedOverlay';

import TestingModal from './Modals/TestingModal';
import GameResult from './Modals/GameResult/GameResult';
import DropdownMenuModalContent from '../Menu/Modals/DropdownMenuModalContent';

import UserSettings from '../Menu/Modals/UserSettings';
import CustomModal from '../Components/Components/Modal';

import config from '../../../../constants/config';

import { returnToAdminReturnToken } from '../../../../actions/users';

import TopSettingsSection from '../Components/Layout/TopSettingsSection';

import * as constants from '../../../../constants/constants';
import { setRoomEnded, setTournamentRoomEnded } from '../../../../actions/room';
// import humanSmallImg from '../../../../images/redesign/tutorial/human-small.png';
import debuggerImg from '../../../../images/debugIco.svg';
import chatLinkImg from '../../../../images/redesign/icons/chat.svg';
import chatLinkActiveImg from '../../../../images/redesign/mobile/icons/chat-active.svg';
import chatLinkLightImg from '../../../../images/redesign/light-mode/icons/chat-light-icon.svg';
import infoImg from '../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../images/redesign/light-mode/player/info.svg';
import roomOutImg from '../../../../images/redesign/mobile/icons/room-out.svg';
import roomOutImgLight from '../../../../images/redesign/mobile/light-mode/icons/room-out.svg';
import gameScoreImg from '../../../../images/redesign/mobile/icons/game-score.svg';
import gameScoreImgLight from '../../../../images/redesign/mobile/light-mode/icons/game-score.svg';
import gameScoreActiveImg from '../../../../images/redesign/mobile/icons/game-score-active.svg';
import IconPopover from '../Components/Components/IconPopover';
import { withRouter } from 'react-router-dom';
import StaticElements from './Components/StaticElements';
import { filter, map } from 'lodash';
import { DropdownItem, Label } from 'reactstrap';
import CustomDropdown from '../Components/Components/CustomDropdown';


class StudentZole extends Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    globalParams: PropTypes.shape(),
    member: PropTypes.shape(),
    supportChatStatus: PropTypes.shape(),
    errorNotification: PropTypes.string,
    closeReason: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    gameSettings: PropTypes.shape(),
    showEndResultModal: PropTypes.bool,
    currentTableLoaded: PropTypes.bool,
    automated: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    isWebVersion: PropTypes.bool,
    setShowEndResultModal: PropTypes.func.isRequired,
    user: PropTypes.string,
    myPos: PropTypes.string,
    currentType: PropTypes.string,
    chooseGameType: PropTypes.func.isRequired,
    playCard: PropTypes.func.isRequired,
    endRoom: PropTypes.func.isRequired,
    roomId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    leaveRoom: PropTypes.func.isRequired,
    quitRound: PropTypes.func.isRequired,
    closeResultNotif: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    setSupportAsRead: PropTypes.func.isRequired,
    quitRoundPressed: PropTypes.bool,
    sendGift: PropTypes.func.isRequired,
    resetErrorNotification: PropTypes.func.isRequired,
    closeLevelNotification: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    error: PropTypes.string,
    userSettings: PropTypes.shape().isRequired,
    toggleFullscreen: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
    toggleScoreTable: PropTypes.func.isRequired,
    toggleMobileScoreTable: PropTypes.func.isRequired,
    fetchCardsOnTable: PropTypes.func.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    toggleNewDesign: PropTypes.func.isRequired,
    players: PropTypes.shape(),
    toggleCardsOnLoading: PropTypes.func.isRequired,
    cardsOnLoading: PropTypes.bool,
    screenMode: PropTypes.string,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    lastRound: PropTypes.bool,
    setRoomEndedFunction: PropTypes.func.isRequired,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    returnToAdmin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: '',
    myPos: null,
    errorNotification: '',
    closeReason: {},
    notificationSettings: {},
    gameSettings: {},
    cards: [],
    member: {},
    currentType: '',
    error: null,
    globalParams: {},
    quitRoundPressed: false,
    supportChatStatus: null,
    showEndResultModal: false,
    currentTableLoaded: false,
    automated: false,
    isFullscreen: false,
    isFullscreenEnabled: false,
    isWebVersion: false,
    cardsOnTable: [],
    players: {},
    cardsOnLoading: true,
    screenMode: constants.SCREEN_MODE.normal,
    fontMode: constants.FONT_MODE.normal,
    lastRound: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalType: '',
      supportModalOpen: false,
      giftsModal: false,
      initialSelected: '',
      cards: [],
      selectedCard: null,
      doubleClickSelectedCard: null,
      tableIsInProgress: false,
      cardPlayClicked: false,
      leavePenalty: null,
      chooseTypeClicked: false,
      endResultWasClosed: false,
      windowHeight: undefined,
      windowWidth: undefined,
      mobileDropdownModalOpened: false,
      mobileScoreTableOpen: false,
      mobileChatOpen: false,
      gameStatsMobileOpen: false,
      roomOutPopoverOpen: false,
      roomInfoPopoverOpen: false,
      openHolidayDebugger: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES_INGAME[0]],
      holidayTest: false,
      adminError: '',
      testingModal: false,
      showCreatedRoomMessage: false,
    };

    this.timerCreatedRoom = null;

    this.gameTypeHolderRef = React.createRef();
  }

  componentDidMount() {
    const {
      globalParams,
      member,
      closeReason,
      currentType,
      notificationSettings,
      userSettings,
      //  cards,
    } = this.props;

    const { modalType } = this.state;

    try {
      if (!localStorage.getItem('initialLevel') && member.lvl) { //x
        localStorage.setItem('initialLevel', member.lvl); //x
      }
    } catch (err) {
      console.log(err);
    }


    if (closeReason && Object.keys(closeReason).length !== 0) {
      if (closeReason.reason) {
        this.setState({
          openModal: true,
          modalType: closeReason.reason,
        });
      }
    } else {
      if (globalParams) {
        const { gameResult, lightningGame, fastGame } = globalParams;

        const { gameResultModalShown } = this.state;
        if (gameResult && !gameResultModalShown) {
          this.setState({
            openGameResultModal: true,
            gameResultModalShown: true,
            selectedCard: null,
          });

          const closeTime = lightningGame ? 2000 : fastGame ? 3000 : 5000;
          this.closeGameResultTimer = setTimeout(() => {
            this.closeResultModal();
          }, closeTime); // 2500 originally
        } else if (modalType === 'gameResult' && !gameResult) {
          this.setState({
            openGameResultModal: false,
            gameResultModalShown: false,
          });
        }
      }

      // if (member.level < 10 && globalParams && globalParams.proRoom) {
      //   this.setState({
      //     openModal: true,
      //     modalType: 'proRoom',
      //   });
      // } else if (modalType === 'proRoom' && member.level >= 10) {
      //   this.setState({
      //     openModal: false,
      //     modalType: '',
      //   });
      // }
    }

    if (currentType) {
      this.animateGameTypeFromCenter();
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    const {
      globalParams, closeReason, quitRoundPressed, notificationSettings, userSettings,
    } = nextProps;

    const newMember = nextProps.member;
    const newShowEndResultModal = nextProps.showEndResultModal;

    const { modalType, openModal, quitRound, openGameResultModal, gameResultModalShown } = this.state;
    const { member, showEndResultModal } = this.props;

    if (closeReason && Object.keys(closeReason).length !== 0) {
    } else {
   //   console.log('notificationSettings', notificationSettings, newShowEndResultModal, showEndResultModal);

      const endResultWasClosed = (globalParams.closeReason && notificationSettings && notificationSettings.showAtRoomEnd === false) || (!newShowEndResultModal && showEndResultModal);
   //   console.log('endResultWasClosed', endResultWasClosed, globalParams);



      if (globalParams) {
        const { gameResult, lightningGame, fastGame } = globalParams;
        const { gameResultModalShown } = this.state;

    //    console.log('gameResultModalShown', gameResultModalShown, gameResult);

        if (gameResult && !gameResultModalShown) {
          if (notificationSettings && notificationSettings.showAfterRound === false) {
            this.setState({
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
              endResultWasClosed,
            });

            this.closeModal();
            this.closeResultModal();
          } else {
            this.setState({
              openGameResultModal: true,
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
              endResultWasClosed,
            });
            const closeTime = lightningGame ? 2000 : fastGame ? 3000 : 5000;
            this.closeGameResultTimer = setTimeout(() => {
              this.closeResultModal();
            }, closeTime); // 2500 originally
          }
        } else if (!gameResult) {
          const closeReason2 = globalParams.closeReason;

       //   console.log('closeReason2', closeReason2);

          if (!showEndResultModal && closeReason2 && closeReason2 === 'lastRound') {
            const { setShowEndResultModal } = this.props;
            setShowEndResultModal();

            this.setState({
              openGameResultModal: false,
              gameResultModalShown: false,
              endResultWasClosed,
            });
          } else {
            const { openGameResultModal } = this.state;
          //  console.log('openGameResultModal', openGameResultModal, gameResultModalShown);
            if (openGameResultModal || gameResultModalShown) {
              this.setState({
                openGameResultModal: false,
                gameResultModalShown: false,
                endResultWasClosed,
              });
            }
          }
        } else {
          const endResultWasClosedState = this.state.endResultWasClosed;
         // console.log('endResultWasClosedState', endResultWasClosedState);

          if (!endResultWasClosedState) {
            this.setState({
              endResultWasClosed,
            });
          }
        }
      }

      if (!quitRoundPressed && quitRound) {
        this.setState({ quitRound: false });
      }

      // if (
      //   newMember
      //   && !newMember.lvlUpNotification
      //   && openModal
      //   && modalType === 'levelUp'
      // ) {
      //   this.UpdateModal(false, '');
      // }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      errorNotification,
      currentType,
      cards,
      error,
      globalParams,
      roomId,
      fetchCardsOnTable,
      toggleCardsOnLoading,
    } = this.props;
    const {
      openModal, modalType, selectedCard, cards: stateCards, endResultWasClosed, openGameResultModal, leaveRoomCalled, // lastRoundPlayerName,
    } = this.state;

  //  console.log('modalType', modalType, endResultWasClosed, globalParams.closeReason);

    // if (globalParams.closeReason && endResultWasClosed && modalType !== 'levelUp' && (!openGameResultModal)) {
    //   this.leaveRoom();
    // }

   // console.log('test to call leaveRoom test', endResultWasClosed, openGameResultModal, leaveRoomCalled);
    if (globalParams.closeReason && globalParams.roomClosed && endResultWasClosed && !openGameResultModal && !openModal && !leaveRoomCalled) {
   //   console.log('do call leaveRoom test', endResultWasClosed, openGameResultModal, globalParams);

      this.leaveRoom();
    }

    const oldGameState = prevProps.globalParams.gameState;

    if (errorNotification) {
      if (errorNotification === 'low balance') {
        if (!(openModal && modalType === 'lowBalanceGift')) {
          this.UpdateModal(true, 'lowBalanceGift');
        }
      }
    }

    if (oldGameState !== globalParams.gameState && globalParams.gameState === constants.GMAE_STATE.burry) {
      this.animateGameTypeFromCenter();
      fetchCardsOnTable(roomId);
    }

    console.log('cards', cards);

    if (cards) {
      if (!selectedCard && !isEqual(stateCards, cards)) {
        this.setState({ cards: [...cards] });
      } if (selectedCard && !isEqual(stateCards, cards)) {
        setTimeout(() => {
          this.setState({ cards: [...cards] });
        }, 270);
      } else if (!isEqual(stateCards, cards) && (error || (globalParams && globalParams.gameResult))) {
        this.setState({ cards: [...cards], selectedCard: null });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    clearTimeout(this.closeReasonTimeoutID);
    clearTimeout(this.closeGameResultTimer);
    if (this.timerCreatedRoom) {
      clearTimeout(this.timerCreatedRoom);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  setRoomOutPopoverOpen = (val) => {
    this.setState({ roomOutPopoverOpen: val });
  }

  setRoomInfoPopoverOpen = (val) => {
    this.setState({ roomInfoPopoverOpen: val });
  }

  handleResize = () => this.setState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });

  tableInProgress = (val) => {
    this.setState({ tableIsInProgress: val });
  }

  animateGameTypeFromCenter = () => {
    /* var startPos = {
      left : window.innerWidth / 2,
      top : window.innerHeight / 2 - 300/2
    }; */

    // var endPos = this.gameTypeHolderRef.current.getBoundingClientRect();

    gsap.to(this.gameTypeHolderRef.current, { /* scale: 0.3, x: startPos.left-endPos.left, y: startPos.top-endPos.top */x: 0, y: -100, duration: 0 });
    gsap.to(this.gameTypeHolderRef.current, {
      x: 0, y: 0, scale: 1, duration: 1.5,
    }, { ease: 'elastic' });
  }

  removeSelectedCard = () => {
    const { cards } = this.props;
    this.setState({ cards, selectedCard: null });
  }

  UpdateModal = (openModal, modalType) => {
    const { resetErrorNotification } = this.props;
    resetErrorNotification();
    this.setState({
      openModal,
      modalType,
    });
  };

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollBottom();
        }
      }, 200);
    }
  };

  toggleScoreTable = () => {
    const { playButtonSound, toggleScoreTable, member } = this.props;
    const { uid } = member;

    playButtonSound();
    toggleScoreTable(uid);
  };

  /* quitRound = () => {
    const { quitRound, playButtonSound, notificationSettings } = this.props;

    if (notificationSettings && notificationSettings.quitRound === false) {
      quitRound();
      playButtonSound();
      this.setState({ quitRound: true });
    } else {
      this.setState({ quitRoundModal: true });
    }
  };

  approveQuitRound = () => {
    const { quitRound, playButtonSound } = this.props;

    quitRound();
    playButtonSound();
    this.setState({ quitRound: true, quitRoundModal: false });
  }

  closeQuitRoundModal = () => {
    this.setState({ quitRoundModal: false });
  } */

  chooseType = (selectedType) => {
    const { chooseGameType, playButtonSound, roomId, fetchCardsOnTable } = this.props;
    this.setState({ chooseTypeClicked: true });

    chooseGameType({ selectedType });
    playButtonSound();
    fetchCardsOnTable(roomId);

    setTimeout(() => {
      this.setState({ chooseTypeClicked: false });
    }, 1100);
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    const { playButtonSound } = this.props;
    playButtonSound();

    this.setState({
      openModal: false,
      modalType: '',
    });
  };

  toggleSupport = () => {
    const { setSupportAsRead, supportChatStatus, playButtonSound } = this.props;
    const { supportModalOpen } = this.state;

    // console.log('toggleSupport');

    if (!supportModalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportAsRead();
    }

    playButtonSound();
    this.setState({ supportModalOpen: !supportModalOpen });
  };

  toggleGiftsModal = (initialSelected, disabled, forceState) => {
    const { playButtonSound, fetchGifts } = this.props;
    const { giftsModal } = this.state;

    console.log('toggleGiftsModal', initialSelected, forceState, disabled);

    if (disabled) {
      return null;
    }

    if (!giftsModal) {
      //  fetchGifts();
      playButtonSound();
    }

    this.setState(prevState => ({
      giftsModal: forceState !== undefined ? forceState : (!prevState.giftsModal),
      initialSelected,
    }));
  };

  closeResultModal = () => {
    console.log('closeResultModal');
    const { closeResultNotif, globalParams, playButtonSound } = this.props;
    if (!globalParams.closeReason) {
      playButtonSound();
    }
    closeResultNotif();

    if (globalParams.closeReason) {
      const { setShowEndResultModal } = this.props;
      setShowEndResultModal();
    }

    this.setState({
      openGameResultModal: false,
      quitRound: false,
      selectedCard: null,
      doubleClickSelectedCard: null,
    });
  }

  leaveRoom = () => {
    const { leaveRoom, setRoomEndedFunction, setTournamentRoomEndedFunction, globalParams } = this.props;

    console.log('leaveRoom function');

    try {
      localStorage.removeItem('initialLevel'); //x
      localStorage.removeItem('newLevel'); //x
    } catch (err) {
      console.log(err);
    }

    this.setState({ leaveRoomCalled: true });

    setRoomEndedFunction(true);
    if (globalParams.tournamentRoom) {
      setTournamentRoomEndedFunction(true);
    }
    leaveRoom();
  }

  exitRoomNotification = () => {
    const {
      leaveRoom, playButtonSound, globalParams, notificationSettings,
    } = this.props;
    if (((globalParams && !globalParams.roomClosed) || !globalParams)) {
      if (notificationSettings && notificationSettings.leaveRoom === false) {
        leaveRoom();
      } else {
        playButtonSound();

        leaveRoom(true);

        const {
          minGames, bet, rPlayed, fourPRoom
        } = globalParams;

        let betValue;
        if (bet === '1:1') {
          betValue = 1;
        } else if (bet === '1:5') {
          betValue = 5;
        } else if (bet === '1:10') {
          betValue = 10;
        } else if (bet === '1:25') {
          betValue = 25;
        } else if (bet === '1:50') {
          betValue = 50;
        } else if (bet === '1:100') {
          betValue = 100;
        } else if (bet === '1:250') {
          betValue = 250;
        } else if (bet === '1:500') {
          betValue = 500;
        } else if (bet === '1:1000') {
          betValue = 1000;
        } else if (bet === '1:5000') {
          betValue = 5000;
        } else if (bet === '1:10000') {
          betValue = 10000;
        }

        let leavePenalty = fourPRoom ? betValue * 24 : betValue * 16;

        if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
          leavePenalty += ((minGames - rPlayed) * 10 * betValue);
        }

        this.setState({
          openModal: true,
          modalType: 'leaveRoom',
          leavePenalty,
        });
      }
    } else {
      leaveRoom();
    }
  }

  playCardWrapper = (e, card) => {
    const { gameSettings } = this.props;
    const { cardPlayClicked } = this.state;

    //    console.log('playCardWrapper', { gameSettings, card });

    if (!card.allowed) {
      AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
        error: 'Card not allowed.',
      });

      this.setState({ cardPlayClicked: false });
      return null;
    }

    if (cardPlayClicked) {
      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 100);

      return null;
    }

    if (gameSettings && gameSettings.doubleClickPlay) {
      const { doubleClickSelectedCard } = this.state;

      if (doubleClickSelectedCard && doubleClickSelectedCard.card === card.card) {
        this.playCard(e, card);
      } else {
        this.setState({ doubleClickSelectedCard: card });
      }
    } else {
      this.playCard(e, card);
    }
  }

  playCard = (e, card) => {
    const {
      playCard,
      globalParams,
      myPos,
    } = this.props;

    const { tableIsInProgress } = this.state;

    if (myPos) {
      this.setState({ cardPlayClicked: true });

      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }

      if (globalParams && globalParams.gameState === 'burry') {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else if (!tableIsInProgress) {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else {
        setTimeout(() => {
          playCard({ selectedCard: card.card, myPos }).then(() => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
            this.setState({ cardPlayClicked: false });
          }).catch((err) => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
              error: err,
            });

            this.setState({ cardPlayClicked: false });
          });
        }, 100);
      }
    } else {
      playCard({ selectedCard: card.card, init: false, myPos: '' });
      AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);

      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 800);

      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }
    }
  }

  showUserSettingsModal = () => {
    const { showUserSettingsModal } = this.state;

    if (!showUserSettingsModal) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open Settings',
      });
    }

    this.setState(prevState => ({ showUserSettingsModal: !prevState.showUserSettingsModal }));
  }

  OpenMobileDropdownModal = () => {
    this.setState({
      mobileDropdownModalOpened: true,
    });
  }

  CloseMobiledropdownModal = () => {
    this.setState({
      mobileDropdownModalOpened: false,
    });
  }

  toggleChat = () => {
    this.setState(prevState => ({
      mobileChatOpen: !prevState.mobileChatOpen,
    }));
  }

  toggleMobileScoreTableOpen = () => {
    const { playButtonSound, toggleScoreTable, toggleMobileScoreTable, member } = this.props;
    const { uid, mobileScoreTableOpen } = member;

    playButtonSound();
    toggleMobileScoreTable(uid, mobileScoreTableOpen);

    this.setState(prevState => ({
      mobileScoreTableOpen: !prevState.mobileScoreTableOpen,
    }));
  }

  toggleGameStatusMobile = () => {
    this.setState(prevState => ({
      gameStatsMobileOpen: !prevState.gameStatsMobileOpen,
    }));
  }

  returnToAdmin = () => {
    const { member, returnToAdmin, adminReturnToken, history, toggleNewDesign, toggleScreenMode, i18n } = this.props;

    console.log('call returnToAdmin', adminReturnToken);

    if (adminReturnToken) {
      returnToAdmin(adminReturnToken, toggleNewDesign, toggleScreenMode, i18n, member.uid).then((res) => {
        console.log('returnToAdmin res', res);

        if (res === 'success') {
          history.push('/admin');
        }
      })
    }
  }

  toggleHolidayDebuggerModal = () => {
    const { openHolidayDebugger } = this.setState;
    if (openHolidayDebugger) {
      this.setState({
        selectedTestHoliday: null,
      });
    }
    this.setState(prevState => ({
      openHolidayDebugger: !prevState.openHolidayDebugger,
    }));
  };

  handleSelectTestHoliday = (id) => {
    this.setState({
      selectedTestHoliday: filter(constants.HOLIDAY_TYPES_INGAME, item => item.id === id),
      openTestHolidayType: false,
    });
  }

  toggleTestHoliday = () => {
    this.setState(prevState => ({
      holidayTest: !prevState.holidayTest,
    }));
  }

  setHolidayTestModal = () => {
    const { selectedTestHoliday } = this.state;

    if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'changeLog') {
      this.setState({
        openChangeLogVersionModal: true,
      })
    } else {
      this.setState({
        holidayTest: true,
      });
    }
    this.setState({
      openHolidayDebugger: false,
    });
  };

  cancelHolidayTest = () => {
    this.setState({
      holidayTest: false,
    });
  };

  toggleTestHolidayType = () => {
    this.setState(prevState => ({
      openTestHolidayType: !prevState.openTestHolidayType,
    }));
  }

  toggleTestModal = () => {
 //   console.log("testingModal Click")
    this.setState(prevState => ({
      testingModal: !prevState.testingModal,
      adminError: '',
    }));
  };

  render() {
    const {
      member,
      user,
      endRoom,
      t,
      globalParams,
      roomId,
      myPos,
      sendGift,
      closeLevelNotification,
      supportChatStatus,
      showEndResultModal,
      setShowEndResultModal,
      userSettings,
      playButtonSound,
      currentTableLoaded,
      automated,
      isFullscreen,
      isFullscreenEnabled,
      isWebVersion,
      notificationSettings,
      toggleFullscreen,
      cardsOnTable,
      toggleNewDesign,
      currentType,
      players,
      cardsOnLoading,
      screenMode,
      toggleFont,
      fontMode,
      lastRound,
      i18n,
      adminReturnToken,
      soundOn,
    } = this.props;

    // console.log('myPosmyPosmyPosmyPos', myPos);

    const {
      chooseTypeClicked,
      openModal,
      modalType,
      newLevel,
      oldLevel,
      openGameResultModal,
      gameResultModalShown,
      supportModalOpen,
      giftsModal,
      initialSelected,
      selectedCard,
      doubleClickSelectedCard,
      tableIsInProgress,
      leavePenalty,
      cardPlayClicked,
      showUserSettingsModal,
      endResultWasClosed,
      windowWidth,
      windowHeight,
      mobileDropdownModalOpened,
      mobileScoreTableOpen,
      mobileChatOpen,
      gameStatsMobileOpen,
      roomOutPopoverOpen,
      roomInfoPopoverOpen,
      openHolidayDebugger,
      openTestHolidayType,
      selectedTestHoliday,
      holidayTest,
      showCreatedRoomMessage,
      adminError,
      testingModal,
    } = this.state;

    if (!roomId) {
      return null;
    }

    console.log('student zole globalParams', globalParams);

  //  console.log('showEndResultModal', showEndResultModal);

    const gameResult = {
      type: 'pass',
      cardsOnTable: {
        cards: ['♦︎-K', '♦︎-9'],
      },
    };
  //  console.log(userSettings.scoreTableOpen, "scoreTableCheck");

    return (
      <div className={classNames('active-player-table', {
        'four-player-table': (globalParams && globalParams.fourPRoom),
        'three-player-table': (globalParams && !globalParams.fourPRoom)
      })}
      >
        {(member.role === 'admin' || member.role === 'tester') && (
          <Media alt="Debugger" className='debugger-holiday debugger-holiday-new-mobile' src={debuggerImg} style={{ zIndex: 11 }} width={32} height={32} onClick={() => this.toggleTestModal()} />
        )}
        {/*  {!(isFullscreen || isWebVersion) ? (
          <div className="game-background" />
        ) : (null)}  */}

        <div className={`top-row top-row-${screenMode} top-row-mobile-landscape`}>
          <div className="logo-wrapper logo-wrapper-mobile">
            <a className="logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
          </div>
          <TopSettingsSection
            t={t}
            config={config}
            logout={this.exitRoomNotification}
            i18n={i18n}
            uid={member.uid}
            newDesign={member.newDesign}
            isFullscreenEnabled={isFullscreenEnabled}
            toggleFullscreen={toggleFullscreen}
            showUserSettingsModal={this.showUserSettingsModal}
            screenMode={screenMode}
            toggleNewDesign={toggleNewDesign}
            fontMode={fontMode}
            roomStatus={true}
            toggleFont={toggleFont}
          />
          <div className={`mobile-top-setting ${config.isInAppFrame() && 'mobile-top-setting-iframe'} d-none`}>
            <div className="top-game-info" id="room-info-id" onMouseEnter={() => this.setRoomInfoPopoverOpen(true)} onMouseLeave={() => this.setRoomInfoPopoverOpen(false)}>
              <Media src={(screenMode === constants.SCREEN_MODE.light) ? lightInfoImg : infoImg} onClick={this.toggleGameStatusMobile} alt="X" className="top-game-info-icon" />
            </div>
            <div className="room-out" id="room-out-id" onMouseEnter={() => this.setRoomOutPopoverOpen(true)} onMouseLeave={() => this.setRoomOutPopoverOpen(false)}>
              <Media src={(screenMode === constants.SCREEN_MODE.light) ? roomOutImgLight : roomOutImg} onClick={this.exitRoomNotification} alt="X" className="room-out-image" />
            </div>
            <IconPopover text={t('common:popovers.roomInfo')} targetId="room-info-id" popoverOpen={roomInfoPopoverOpen} placement="bottom" />
            <IconPopover text={t('common:popovers.leaveRoom')} targetId="room-out-id" popoverOpen={roomOutPopoverOpen} placement="left" />
          </div>
        </div>

        <div className="active-room-footer d-none">
          <div className="chat-link">
            <Media src={mobileChatOpen ? chatLinkActiveImg : (screenMode === constants.SCREEN_MODE.light) ? chatLinkLightImg : chatLinkImg} onClick={this.toggleChat} alt="X" className="chat-link-image" />
          </div>
          <div className="game-score">
            <Media
              src={(userSettings.scoreTableOpen || userSettings.scoreTableOpen === undefined) && mobileScoreTableOpen ? gameScoreActiveImg : (screenMode === constants.SCREEN_MODE.light) ? gameScoreImgLight : gameScoreImg}
              onClick={config.isInAppFrame() ? null : this.toggleMobileScoreTableOpen}
              onMouseEnter={config.isInAppFrame() ? this.toggleMobileScoreTableOpen : null}
              onMouseLeave={config.isInAppFrame() ? this.toggleMobileScoreTableOpen : null}
              alt="X"
              className="game-score-image"
            />
          </div>
        </div>

        <CustomModal
          isOpen={showUserSettingsModal}
          toggle={this.showUserSettingsModal}
          size="lg"
          title={t('menu.settings')}
          modalVerticalCentering
          body={<UserSettings toggleNewDesign={toggleNewDesign} showUserSettingsModal={this.showUserSettingsModal} />}
          screenMode={screenMode}
        />

        <DealingNewCards
          show={(globalParams && !globalParams.roomClosed && globalParams.gameState === 'results' && !tableIsInProgress && /*(!cardsOnTable || !cardsOnTable.length) &&*/ !openGameResultModal && gameResultModalShown)}
          isMobile={windowWidth <= 1280}
        />

        {(!showEndResultModal && !(user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user)) && (
          <AutomatedOverlay roomId={roomId} automated={automated} t={t} isMobile={windowWidth <= 1280} screenMode={screenMode} />
        )}

        <Row>
          {/* TO DO change for 2 player */}
          {currentTableLoaded && (
            <CardsOnTable
              removeSelectedCard={this.removeSelectedCard}
              tableInProgress={this.tableInProgress}
              myPos={myPos}
              soundOn={soundOn}
              tableIsInProgress={tableIsInProgress}
            />
          )}

          <PreviousRound
            playButtonSound={playButtonSound}
            roomId={roomId}
            screenMode={screenMode}
            studentRoom={globalParams.studentRoom}
          />

          {/* Players */}
          {/* TO DO change for 2 player */}
          <Players
            studentRoom={globalParams.studentRoom}
            soundOn={soundOn}
            myPos={myPos}
            gameT={t}
            roomId={roomId}
            endRoom={endRoom}
            gameResultModalShown={gameResultModalShown}
            toggleGiftsModal={this.toggleGiftsModal}
            closeResultModal={this.closeResultModal}
            showEndResultModal={showEndResultModal}
            playCardWrapper={this.playCardWrapper}
            selectedCard={selectedCard}
            doubleClickSelectedCard={doubleClickSelectedCard}
            tableIsInProgress={tableIsInProgress}
            cardPlayClicked={cardPlayClicked}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            cardsOnLoading={cardsOnLoading}
            screenMode={screenMode}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            selectedTestHoliday={selectedTestHoliday}
            holidayTest={holidayTest}
            isInAppFrame={config.isInAppFrame()}
          />

          {/* Choosing game type */}
          <ChooseGameType

            chooseTypeClicked={chooseTypeClicked}
            chooseType={this.chooseType}
            t={t}
            smallGame={globalParams.smallGame}
            studentRoom={globalParams.studentRoom}
            show={globalParams && globalParams.gameState === 'choose' && globalParams.talking && user && globalParams.talking === user}
          />
          {!config.isInAppFrame() && (
            <ChooseGameTypeMobile
              chooseTypeClicked={chooseTypeClicked}
              chooseType={this.chooseType}
              t={t}
              smallGame={globalParams.smallGame}
              studentRoom={globalParams.studentRoom}
              show={globalParams && globalParams.gameState === 'choose' && globalParams.talking && user && globalParams.talking === user}
            />
          )}



          <GameStats gameStatsMobileOpen={gameStatsMobileOpen} toggleGameStatusMobile={this.toggleGameStatusMobile} screenMode={screenMode} />

          {userSettings.scoreTableOpen || userSettings.scoreTableOpen === undefined ? (
            <div className="score-table-toggle-open-new score-table-toggle-o">
              <div className="room-chat-open-button-icon" onClick={this.toggleScoreTable} />
            </div>
          ) : (
            <div className="score-table-toggle-closed-new score-table-toggle-c">
              <div className="room-chat-close-button-icon" onClick={this.toggleScoreTable} />
            </div>
          )}

          {
            windowWidth > 1280 && (
              <ScoreTable
                roomId={roomId}
                isFullscreen={isFullscreen || isWebVersion}
                scoreTableOpen={(userSettings.scoreTableOpen) ? true : (userSettings.scoreTableOpen === undefined)}
                mobileScoreTableOpen={mobileScoreTableOpen}
                screenMode={screenMode}
                studentRoom={globalParams.studentRoom}
              />
            )
          }
          {
            windowWidth <= 1280 && (
              <ScoreTable
                roomId={roomId}
                isFullscreen={isFullscreen || isWebVersion}
                scoreTableOpen={(userSettings.scoreTableOpen || userSettings.scoreTableOpen === undefined) && mobileScoreTableOpen ? true : false}
                screenMode={screenMode}
                studentRoom={globalParams.studentRoom}
              />
            )
          }
          {
            !openGameResultModal && (
              <EndResultModal
                roomId={roomId}
                modalOpen={(showEndResultModal || (user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user)) && !endResultWasClosed}
                leaveRoom={this.leaveRoom}
                gameT={t}
                playButtonSound={playButtonSound}
                closeReason={globalParams.closeReason}
                bet={globalParams.bet}
                minGames={globalParams.minGames || 0}
                rPlayed={globalParams.rPlayed || 0}
                tournamentRoom={globalParams.tournamentRoom || false}
                tournamentRound={globalParams.tournamentRound || null}
                fourthPlayerMissed={!!((user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user))}
                member={member}
                notificationSettings={notificationSettings}
                fourPRoom={globalParams.fourPRoom}
                screenMode={screenMode}
                setShowEndResultModal={setShowEndResultModal}
                studentRoom={globalParams.studentRoom}
              />
            )
          }

          <GameResult
            closeResultModal={this.closeResultModal}
            closeModal={this.closeModal}
            openGameResultModal={openGameResultModal}
            gameResult={globalParams.gameResult}
            minGames={globalParams.minGames}
            rPlayed={globalParams.rPlayed}
            players={players}
            soundOn={soundOn}
            memberUid={member.uid}
            lastRound={lastRound}
          />

          <StaticElements selectedTestHoliday={selectedTestHoliday} holidayTest={holidayTest} />
          <Notification
            openModal={openModal}
            soundOn={soundOn}
            modalType={modalType}
            leavePenalty={leavePenalty}
            closeModal={this.closeModal}
            closeResultModal={this.closeResultModal}
            leaveRoom={this.leaveRoom}
            newLevel={newLevel}
            oldLevel={oldLevel}
            closeLevelNotification={closeLevelNotification}
            screenMode={screenMode}
            endResultWasClosed={endResultWasClosed}
            toggleGiftsModal={this.toggleGiftsModal}
          />
          <Chat
            toggleSupport={this.toggleSupport}
            roomId={roomId}
            uid={user}
            supportChatStatus={supportChatStatus}
            playButtonSound={playButtonSound}
            fontMode={fontMode}
            screenMode={screenMode}
            mobileChatOpen={mobileChatOpen}
          />

          {
            windowWidth > 1280 && (
              <SendGift
                sendGift={sendGift}
                modalOpen={giftsModal}
                toggle={this.toggleGiftsModal}
                roomId={roomId}
                initialSelected={initialSelected}
                playButtonSound={playButtonSound}
                notificationSettings={notificationSettings}
                isFullscreen={isFullscreen || isWebVersion}
              />
            )
          }

          {
            windowWidth <= 1280 && (
              <SendGiftMobile
                modalOpen={giftsModal}
                sendGift={sendGift}
                playButtonSound={playButtonSound}
                notificationSettings={notificationSettings}
                initialSelected={initialSelected}
                isFullscreen={isFullscreen || isWebVersion}
                roomId={roomId}
                toggle={this.toggleGiftsModal}
                uid={member.uid}
              />
            )
          }

          <ContactSupport
            modalOpen={supportModalOpen}
            toggle={this.toggleSupport}
            screenMode={screenMode}
          />
        </Row>

        <TestingModal
          roomId={roomId}
          selectedTestHoliday={selectedTestHoliday}
          openTestHolidayType={openTestHolidayType}
          handleSelectTestHoliday={this.handleSelectTestHoliday}
          toggleTestHolidayType={this.toggleTestHolidayType}
          toggleTestHoliday={this.toggleTestHoliday}
          toggleTestModal={this.toggleTestModal}
          adminError={adminError}
          testingModal={testingModal}
          studentRoom={globalParams.studentRoom}
        />

        {(member && adminReturnToken) && (
          <Button
            className="btn holiday-debugger-button"
            onClick={() => this.returnToAdmin()}
            style={{ zIndex: 999, position: 'fixed', left: 225, top: 25, width: 150 }}
          >
            Return To Admin
          </Button>
        )}

        <Clock offset={member.offset} isInGame={true} />

        <CustomModal
          isOpen={mobileDropdownModalOpened}
          addClassName="mobile-dropdown-menu-modal"
          // inlineClassName="d-none"
          toggle={this.CloseMobiledropdownModal}
          body={(
            <DropdownMenuModalContent
              t={t}
              i18n={i18n}
              supportModalOpen={supportModalOpen}
              notificationSettings={notificationSettings}
              supportChatStatus={member.supportChatStatus}
              toggleBottomBarTab={this.toggleBottomBarTab}
              toggleSupport={this.toggleSupport}
              screenMode={screenMode}
              handleClickStart={(e) => this.handleClickStart(e)}
              config={config}
              uid={member.uid}
              isFullscreenEnabled={isFullscreenEnabled}
              toggleFullscreen={toggleFullscreen}
              showUserSettingsModal={this.showUserSettingsModal}
              toggleNewDesign={toggleNewDesign}
              fontMode={fontMode}
              toggleFont={toggleFont}
              logout={this.logout}
              roomStatus={false}
              closeModal={this.CloseMobiledropdownModal}
            />
          )}
          footerClose={false}
        />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminReturnToken: state.users.adminReturnToken || null,
});

const mapDispatchToProps = {
  setRoomEndedFunction: setRoomEnded,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
  returnToAdmin: returnToAdminReturnToken,
};

//export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['game', 'common'])(Zole));
export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    withTranslation(['game', 'common'])(StudentZole)
  )
);
