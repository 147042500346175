import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { map, size} from 'lodash';

import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';

import CustomModal from '../../Components/Components/Modal';

import {
  debugSetParam,
} from '../../../../../actions/game';

import {
  disableTimer,
  toggleAutomation,
  setNextDealCards,
  setUserBal,
} from '../../../../../actions/admin';
import CustomDropdown from '../../Components/Components/CustomDropdown';
import { DropdownItem } from 'reactstrap';
import { HOLIDAY_TYPES_INGAME } from '../../../../../constants/constants';

import { filter } from 'lodash';

const TESTING_ALL_CARDS = ['♣︎-Q', '♠︎-Q', '♥-Q', '♦︎-Q', '♣︎-J', '♠︎-J', '♥-J', '♦︎-J', '♦︎-A', '♦︎-10', '♦︎-K', '♦︎-9', '♦︎-8', '♦︎-7', '♥-A', '♥-10', '♥-K', '♥-9', '♠︎-A', '♠︎-10', '♠︎-K', '♠︎-9', '♣︎-A', '♣︎-10', '♣︎-K', '♣︎-9'];

class TestingModal extends Component {
  static propTypes = {
    role: PropTypes.string,
    disableTime: PropTypes.func.isRequired,
    timerDisabled: PropTypes.bool,
    p1Name: PropTypes.string,
    p2Name: PropTypes.string,
    p3Name: PropTypes.string,
    roomId: PropTypes.string,
    setNextDeal: PropTypes.func.isRequired,
    setUserBalance: PropTypes.func.isRequired,
    disableAutomation: PropTypes.func.isRequired,
    debugParam: PropTypes.func.isRequired,
    uid: PropTypes.string,
    tournamentId: PropTypes.string,
    myPos: PropTypes.string,
    roomAutomated: PropTypes.bool,
    selectedTestHoliday: PropTypes.array,
    openTestHolidayType: PropTypes.bool,
    handleSelectTestHoliday: PropTypes.func,
    toggleTestHolidayType: PropTypes.func,
    toggleTestHoliday: PropTypes.func,
    toggleTestModal: PropTypes.func,
  }

  static defaultProps = {
    timerDisabled: false,
    role: null,
    p1Name: null,
    p2Name: null,
    p3Name: null,
    roomId: null,
    uid: null,
    tournamentId: null,
    myPos: null,
    roomAutomated: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      // adminError: '',
      // testingModal: false,
    };
  }


  handleChange = (event) => {
    const value = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  };

  setNextDealCards = () => {
    const { roomId, setNextDeal, sittingOut, studentRoom } = this.props;
    const { nextDealCards1, nextDealCards2, nextDealCards3, nextDealCards4, nextDealTableCards, nextDealCards1Visible, nextDealCards1Hidden, nextDealCards2Visible, nextDealCards2Hidden } = this.state;

    const strippedCards1 = size(nextDealCards1) > 0 ? nextDealCards1.replace(' ', '') : [];
    const strippedCards2 = size(nextDealCards2) > 0 ? nextDealCards2.replace(' ', '') : [];
    const strippedCards3 = size(nextDealCards3) > 0 ? nextDealCards3.replace(' ', '') : [];
    const strippedCards4 = size(nextDealCards4) > 0 ? nextDealCards4.replace(' ', '') : [];
    const strippedDealTableCards = size(nextDealTableCards) > 0 ? nextDealTableCards.replace(' ', '') : [];
    const strippedCards1Visible = size(nextDealCards1Visible) > 0 ? nextDealCards1Visible.replace(' ', '') : [];
    const strippedCards1Hidden = size(nextDealCards1Hidden) > 0 ? nextDealCards1Hidden.replace(' ', '') : [];
    const strippedCards2Visible = size(nextDealCards2Visible) > 0 ? nextDealCards2Visible.replace(' ', '') : [];
    const strippedCards2Hidden = size(nextDealCards2Hidden) > 0 ? nextDealCards2Hidden.replace(' ', '') : [];

    const cardsSplit1 = size(strippedCards1) > 0 ? strippedCards1.split(',') : [];
    const cardsSplit2 = size(strippedCards2) > 0 ? strippedCards2.split(',') : [];
    const cardsSplit3 = size(strippedCards3) > 0 ? strippedCards3.split(',') : [];
    const cardsSplit4 = size(strippedCards4) > 0 ? strippedCards4.split(',') : [];
    const tableCardsSplit = size(strippedDealTableCards) > 0 ? strippedDealTableCards.split(',') : [];
    const cardsSplit1Visible = size(strippedCards1Visible) > 0 ? strippedCards1Visible.split(',') : [];
    const cardsSplit1Hidden = size(strippedCards1Hidden) > 0 ? strippedCards1Hidden.split(',') : [];
    const cardsSplit2Visible = size(strippedCards2Visible) > 0 ? strippedCards2Visible.split(',') : [];
    const cardsSplit2Hidden = size(strippedCards2Hidden) > 0 ? strippedCards2Hidden.split(',') : [];

    let player1Cards = [];
    let player2Cards = [];
    let player3Cards = [];
    let player4Cards = [];
    let tableCards = [];
    let player1CardsVisible = [];
    let player1CardsHidden = [];
    let player2CardsVisible = [];
    let player2CardsHidden = [];

    if (size(cardsSplit1) > 0) {
      map(cardsSplit1, item => {
        player1Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit2) > 0) {
      map(cardsSplit2, item => {
        player2Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit3) > 0) {
      map(cardsSplit3, item => {
        player3Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit4) > 0) {
      map(cardsSplit4, item => {
        player4Cards.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(tableCardsSplit) > 0) {
      map(tableCardsSplit, item => {
        tableCards.push(TESTING_ALL_CARDS[item]);
      })
    }

    if (size(cardsSplit1Visible) > 0) {
      map(cardsSplit1Visible, item => {
        player1CardsVisible.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit1Hidden) > 0) {
      map(cardsSplit1Hidden, item => {
        player1CardsHidden.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit2Visible) > 0) {
      map(cardsSplit2Visible, item => {
        player2CardsVisible.push(TESTING_ALL_CARDS[item]);
      })
    }
    if (size(cardsSplit2Hidden) > 0) {
      map(cardsSplit2Hidden, item => {
        player2CardsHidden.push(TESTING_ALL_CARDS[item]);
      })
    }

    let playerCards = {
      player1: size(player1Cards) > 0 ? player1Cards : null,
      player2: size(player2Cards) > 0 ? player2Cards : null,
      player3: size(player3Cards) > 0 ? player3Cards : null,
      tableCards: size(tableCards) > 0 ? tableCards : null,
    }

    if (sittingOut) {
      playerCards = {
        player1: size(player1Cards) > 0 ? player1Cards : null,
        player2: size(player2Cards) > 0 ? player2Cards : null,
        player3: size(player3Cards) > 0 ? player3Cards : null,
        player4: size(player4Cards) > 0 ? player4Cards : null,
        tableCards: size(tableCards) > 0 ? tableCards : null,
      }
    }

    if (studentRoom) {
      playerCards = {
        player1: size(player1Cards) > 0 ? player1Cards : null,
        player2: size(player2Cards) > 0 ? player2Cards : null,
        player1Visible: size(player1CardsVisible) > 0 ? player1CardsVisible : null,
        player1Hidden: size(player1CardsHidden) > 0 ? player1CardsHidden : null,
        player2Visible: size(player2CardsVisible) > 0 ? player2CardsVisible : null,
        player2Hidden: size(player2CardsHidden) > 0 ? player2CardsHidden : null,
        tableCards: size(tableCards) > 0 ? tableCards : null,
      }
    }

    /* const playerCards = {
      player1: size(player1Cards) > 0 ? player1Cards : null,
      player2: size(player2Cards) > 0 ? player2Cards : null,
      player3: size(player3Cards) > 0 ? player3Cards : null,
      tableCards: size(tableCards) > 0 ? tableCards : null,
    } */

    console.log(playerCards, tableCards, "checkCheckCheck");

    setNextDeal(roomId, playerCards);
    this.setState({ adminError: '' });
  };

  setUserBal = () => {
    const {
      setUserBalance,
      uid,
      roomId,
      //  setUserTournamentBal,
      tournamentId,
      myPos,
    } = this.props;

    const { newBal } = this.state;

    if (myPos && uid && newBal && roomId) {
      if (tournamentId) {
      //  setUserTournamentBal(roomId, tournamentId, uid, myPos, newBal);
      } else {
        setUserBalance(roomId, uid, myPos, newBal);
      }
    }
  };

  render() {
    const {
      role, disableTime, timerDisabled, roomId, p1Name, p2Name, p3Name, p4Name, debugParam, roomAutomated, disableAutomation, sittingOut, studentRoom,
      selectedTestHoliday, openTestHolidayType, handleSelectTestHoliday, toggleTestHolidayType, toggleTestHoliday, toggleTestModal,
      adminError, testingModal,
    } = this.props;

    if (role === 'admin' || role === 'tester') {
      return (
        <Fragment>


          <CustomModal
            isOpen={testingModal}
            toggle={toggleTestModal}
            size="lg"
            title={'Debug'}
            footer={(
              <Button className="modal-footer-button" onClick={toggleTestModal}>
                Aizvērt
              </Button>
            )}
            body={
              <>
                <div style={{ color: 'red', fontSize: 14, marginBottom: 3 }}>
                  {adminError}
                </div>
                <div className="test-panel-half-section">
                  <Button
                    className="test-panel-button test-panel-button-top"
                    onClick={() => disableTime(roomId)}
                  >
                    {timerDisabled
                      ? 'Ieslēgt taimeri'
                      : 'Izslēgt taimeri'}
                  </Button>
                </div>

                <div className="test-panel-half-section">
                  <Button
                    className="test-panel-button test-panel-button-top"
                    onClick={() => disableAutomation(roomId)}
                  >
                    {roomAutomated
                      ? 'Izslēgt automāciju'
                      : 'Ieslēgt automāciju'}
                  </Button>
                </div>

                <div style={{ fontSize: 12, marginBottom: 3 }}>
                  Kārtis ir secībā
                  <table className="test-panel-cards-table">
                    <thead>
                      <tr>
                        <td> 0 </td>
                        <td> 1 </td>
                        <td> 2 </td>
                        <td> 3 </td>
                        <td> 4 </td>
                        <td> 5 </td>
                        <td> 6 </td>
                        <td> 7 </td>
                        <td> 8 </td>
                        <td> 9 </td>
                        <td> 10 </td>
                        <td> 11 </td>
                        <td> 12 </td>
                        <td> 13 </td>
                        <td> 14 </td>
                        <td> 15 </td>
                        <td> 16 </td>
                        <td> 17 </td>
                        <td> 18 </td>
                        <td> 19 </td>
                        <td> 20 </td>
                        <td> 21 </td>
                        <td> 22 </td>
                        <td> 23 </td>
                        <td> 24 </td>
                        <td> 25 </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> ♣︎-Q </td>
                        <td> ♠︎-Q </td>
                        <td> ♥-Q </td>
                        <td> ♦︎-Q </td>
                        <td> ♣︎-J </td>
                        <td> ♠︎-J </td>
                        <td> ♥-J </td>
                        <td> ♦︎-J </td>
                        <td> ♦︎-A </td>
                        <td> ♦︎-10 </td>
                        <td> ♦︎-K </td>
                        <td> ♦︎-9 </td>
                        <td> ♦︎-8 </td>
                        <td> ♦︎-7 </td>
                        <td> ♥-A </td>
                        <td> ♥-10 </td>
                        <td> ♥-K </td>
                        <td> ♥-9 </td>
                        <td> ♠︎-A </td>
                        <td> ♠︎-10 </td>
                        <td> ♠︎-K </td>
                        <td> ♠︎-9 </td>
                        <td> ♣︎-A </td>
                        <td> ♣︎-10 </td>
                        <td> ♣︎-K </td>
                        <td> ♣︎-9 </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                    marginTop: 3,
                    textTransform: 'none',
                  }}
                >
                  {studentRoom ? (`Katram spēlētajam jāpiešķir 12 kārtis (4 rokā, 4 redzamas, 4 paslētas), kāršu nummurus jāatdala ar komatu`) : (`Katram spēlētajam jāpiešķir 8 kārtis, kāršu nummurus jāatdala ar komatu`)}
                </div>


                {!studentRoom ? (
                  <>
                    <div className="test-panel-third-section">
                      <div style={{ fontSize: 12 }}>{p1Name}</div>
                      <Input
                        type="text"
                        name="nextDealCards1"
                        className="test-panel-input"
                        onChange={this.handleChange}
                        placeholder={(sittingOut && sittingOut === "player4") ? 'Next dealer' : ''}
                        disabled={(sittingOut && sittingOut === "player4") ? true : false}
                      />
                    </div>
                    <div className="test-panel-third-section">
                      <div style={{ fontSize: 12 }}>{p2Name}</div>
                      <Input
                        type="text"
                        name="nextDealCards2"
                        className="test-panel-input"
                        onChange={this.handleChange}
                        placeholder={(sittingOut && sittingOut === "player1") ? 'Next dealer' : ''}
                        disabled={(sittingOut && sittingOut === "player1") ? true : false}
                      />
                    </div>
                    <div className="test-panel-third-section">
                      <div style={{ fontSize: 12 }}>{p3Name}</div>
                      <Input
                        type="text"
                        name="nextDealCards3"
                        className="test-panel-input"
                        onChange={this.handleChange}
                        placeholder={(sittingOut && sittingOut === "player2") ? 'Next dealer' : ''}
                        disabled={(sittingOut && sittingOut === "player2") ? true : false}
                      />
                    </div>
                    {sittingOut ? (
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>{p4Name}</div>
                        <Input
                          type="text"
                          name="nextDealCards4"
                          onChange={this.handleChange}
                          className="test-panel-input"
                          placeholder={(sittingOut && sittingOut === "player3") ? 'Next dealer' : ''}
                          disabled={(sittingOut && sittingOut === "player3") ? true : false}
                        />
                      </div>
                    ) : (null)}
                    <div className="test-panel-third-section">
                      <div style={{ fontSize: 12 }}>Table Cards</div>
                      <Input
                        type="text"
                        name="nextDealTableCards"
                        className="test-panel-input"
                        onChange={this.handleChange}
                      />
                    </div>
                  </>
                ) : (
                    <>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>{p1Name}</div>
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Rokā</div>
                        <Input
                          type="text"
                          name="nextDealCards1"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Redzamas</div>
                        <Input
                          type="text"
                          name="nextDealCards1Visible"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Paslēptas</div>
                        <Input
                          type="text"
                          name="nextDealCards1Hidden"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>{p2Name}</div>
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Rokā</div>
                        <Input
                          type="text"
                          name="nextDealCards2"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Redzamas</div>
                        <Input
                          type="text"
                          name="nextDealCards2Visible"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Paslēptas</div>
                        <Input
                          type="text"
                          name="nextDealCards2Hidden"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="test-panel-third-section">
                        <div style={{ fontSize: 12 }}>Table Cards</div>
                        <Input
                          type="text"
                          name="nextDealTableCards"
                          className="test-panel-input"
                          onChange={this.handleChange}
                        />
                      </div>
                  </>
                )}

                <Button
                  className="test-panel-button"
                  onClick={this.setNextDealCards}
                >
                  Likt nākošā dalījuma kārtis
                </Button>

                <Input
                  type="number"
                  name="newBal"
                  className="test-panel-input"
                  onChange={this.handleChange}
                />
                <Button className="test-panel-button" onClick={this.setUserBal}>
                  Mainīt bilanci
                </Button>


                <div className="test-panel-half-section">
                  <div style={{ fontSize: 12 }}>Id</div>
                  <Input
                    type="text"
                    name="debugId"
                    className="test-panel-input"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="test-panel-half-section">
                  <div style={{ fontSize: 12 }}>Vērtība</div>
                  <Input
                    type="text"
                    name="debugValue"
                    className="test-panel-input"
                    onChange={this.handleChange}
                  />
                </div>
                <Button
                  className="test-panel-button"
                  onClick={debugParam}
                >
                  Likt debug parametru
                </Button>
                  <div>Holiday Type</div>
                  <div className="holiday-test-section test-panel-half-section align-top w-50">
                  <CustomDropdown
                    toggle={(
                      <span>
                        {selectedTestHoliday[0].label}
                      </span>
                    )
                    }
                    toggleFunc={toggleTestHolidayType}
                    isOpen={openTestHolidayType}
                    menu={(
                      <>
                        {map(HOLIDAY_TYPES_INGAME, item => (
                          <DropdownItem key={item.id} className={item.id === selectedTestHoliday[0].id ? 'active-item' : ''} onClick={() => { handleSelectTestHoliday(item.id); }}>
                            {item.label}
                          </DropdownItem>
                        ))}
                      </>
                      )}
                    inlineMenuClassName="holiday-test-section-dropdown-list"
                  />
                  </div>
                  <div className="test-panel-half-section pl-1 align-top">
                      <Button className="test-panel-button" onClick={toggleTestHoliday}>Toggle</Button>
                  </div>
              </>
            }
          />
        </Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid,
  role: state.member.role,
  timerDisabled: state.game.globalParams.disableTimer,
  roomAutomated: state.game.globalParams.automated,
  tournamentId: state.game.globalParams.tournamentId,
  sittingOut: state.game.sittingOut,
  myPos: state.game.myPos,
  p1Name: (state.game.players && state.game.players.player1) ? state.game.players.player1.name : '',
  p2Name: (state.game.players && state.game.players.player2) ? state.game.players.player2.name : '',
  p3Name: (state.game.players && state.game.players.player3) ? state.game.players.player3.name : '',
  p4Name: (state.game.players && state.game.players.player4) ? state.game.players.player4.name : '',
});

const mapDispatchToProps = {
  debugParam: debugSetParam,
  setNextDeal: setNextDealCards,
  disableTime: disableTimer,
  disableAutomation: toggleAutomation,
  setUserBalance: setUserBal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestingModal);
